import './App.scss';
import Body from './component/Body';

function App() {
  return (
    <>
    <Body/>
    </>
  );
}

export default App;
