import Header from "./Header"
import Main from "./Main";
import Footer from "./Footer";
const Body = ()=>{
    return(
        <>
         <body>
          <Header/>
          <Main/>
         </body>
          <Footer/>
        </>
    )
}
export default Body;